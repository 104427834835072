.header {
	width: 100%;
	.top-left-nav {
		@include center-box(center, flex-start);
		.up-left {
			@include center-box();
			margin-right: 20px;
			padding-top: 5px;
			.name_pic {
				@include center-box();
				.profilePict {
					@include center-box();
					width: 30px;
					height: 30px;
					border-radius: 50%;
					overflow: hidden;
					border: 1px solid #ccc;
					margin-left: 10px;
					margin-right: 5px;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}
