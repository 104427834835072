.details-tab-panel {
	// Example styles for a slide-out panel
	position: fixed;
	overflow-y: scroll;
	right: 0;
	top: 80px;
	padding-bottom: 80px;
	width: 100%; // Adjust as necessary
	height: 100%;
	background: rgba(#fff, 0.7);

	// transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	@include center-box(flex-end, flex-start);

	// This assumes you toggle a class to show/hide the panel
	&.show {
		transform: translateX(0);
	}
	.details-panel,
	.new-details-panel {
		-webkit-box-shadow: -10px 0 10px 0 #dedede;
		box-shadow: -10px 0 10px 0 #dedede;
		width: 70%;
		background: #fff;
		padding: 26px;
		@media (max-width: 900px) {
			width: 100%;
		}
		.details-number {
			max-width: 1000px;
			margin: 0 auto 50px;
			@include center-box(space-between, flex-start);
			.block-left {
				@include center-box(flex-start, center);

				// .details-number-title {
				// 	font-size: 24px;
				// 	font-weight: 600;
				// 	width: 100%;
				// 	@apply text-secondColor-700;
				// }
			}
			.details-number-title {
				font-size: 24px;
				font-weight: 600;

				@apply text-secondColor-700;
			}
			.block-right {
				@include center-box(flex-end, center);
				.btn-delete-item {
					@extend %red-btn;
					@apply text-redColor-400;
					@include center-box();
					margin-right: 10px;
					svg {
						margin-left: 10px;
					}
				}
				.btn-edit-item {
					@extend %transparent-edit-btn;
					@include center-box();
					svg {
						margin-left: 10px;
					}
				}
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
			}
		}

		.tab-information {
			@extend %detail-blocks;
			max-width: 1000px;
			margin: 0 auto 20px;
			p {
				margin-bottom: 20px;
				border-bottom-style: solid;
				border-bottom-width: 1px;
				@apply border-secondColor-300;
				strong {
					@apply text-secondColor-700;
					margin-right: 20px;
				}
			}
			.field-section-full {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 10px;
				label {
					width: 25%;
				}
				input,
				textarea {
					width: 75%;
					padding: 3px;
					margin-bottom: 10px;
				}
			}
			.field-section-one-half {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 10px;
				label {
					width: 25%;
					max-width: 90px;
				}
				input,
				textarea {
					width: 75%;
					margin-bottom: 10px;
				}
				.p-tab-info {
					border: none;
				}
			}

			.field-section-half {
				@extend %general-fields;
				@include center-box(space-between, center);
				.inp-col {
					@include center-box(flex-start, center);
					width: 48%;
					.p-tab-info {
						border: none;
					}

					label {
						width: 25%;
						padding: 3px 3px 3px 0;
					}
					input,
					textarea,
					select {
						width: 75%;
						margin-bottom: 10px;
					}
					select {
						padding: 10px;
					}
					.p-tab-active {
						@include center-box(flex-start, center);
						border: none;
						strong {
							margin-right: 30px;
						}
					}
				}
			}
		}
	}
	.new-details-panel {
		width: 100%;
		@media (max-width: 900px) {
			width: 100%;
		}

		.saveBtn {
			@apply bg-redColor-500;
			color: #fff;
			border-radius: 5px;
			padding: 10px 20px;
			font-size: 14px;
			font-weight: 600;
			border: none;
			cursor: pointer;
			margin: 20px auto;
			width: 100%;
			max-width: 150px;
			@include center-box();
			&:hover {
				@apply bg-redColor-700;
				color: #fff;
			}
		}
	}

	.modal-form {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5); // Semi-transparent backdrop
		z-index: 2; // Ensure it's below the modal but above other content
		@include center-box(); // Center the backdrop content

		.form-container {
			width: 400px;
			background: white;
			padding: 20px;
			position: relative;
			border-radius: 8px;
			z-index: 4;
			.new-category-input {
				padding: 10px;
				border: 1px solid #ccc;
				border-radius: 4px;
				width: 100%;

				&::placeholder {
					@apply text-secondColor-700;
				}
				&:focus,
				&:hover,
				&:active {
					@apply border-secondColor-300;
				}
			}
			.saveBtn-modal {
				margin: 15px auto 0;
				padding: 10px 15px;
				border-radius: 10px;
				font-size: 14px;
				@apply bg-redColor-500;
				color: #fff;
				&:hover {
					@apply bg-redColor-700;
				}
			}
		}
	}

	// Backdrop styles
	.modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5); // Semi-transparent backdrop
		z-index: 2; // Ensure it's below the modal but above other content
		@include center-box(); // Center the backdrop content
	}
}
