.inventory-page {
	.big-title {
		@extend %big-title;
	}
	.summary-btns {
		@include center-box(space-between, flex-start);
		width: 100%;
		li {
			@include center-box(flex-start, flex-start);
			margin-right: 10px;
			max-width: 259px;
			min-width: 197px;
			margin-bottom: 10px;
			.sb {
				@apply bg-secondColor-200;
				@include center-box(space-between, center);
				border-radius: 10px;
				padding: 15px 25px;
				width: 100%;
				max-width: 259px;
				svg {
					@apply text-secondColor-600;
					margin-right: 10px;
				}
				p {
					@include center-box(flex-start, space-between);
					flex-direction: column;
					line-height: 17px;

					strong {
						width: 100%;
						max-width: 91px;
						margin-bottom: 6px;
					}
				}
			}
		}
	}
	.searchbar {
		margin-top: 38px;
		width: 100%;
		@include center-box(space-between, center);
		.filters {
			@include center-box(flex-start, center);
			button {
				margin-right: 10px;
				border-radius: 32px;
				border: 1px solid #dadada;
				@include center-box(flex-start, center);
				padding: 13px 20px;
				svg {
					margin-left: 10px;
				}
				&:hover {
					@apply bg-secondColor-900;
					@apply text-white;
					border-radius: 32px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent;
				}
			}
		}
		.action-btns {
			.btn-new-user,
			.exp-imp-btn {
				@extend %transparent-btn;
				@include center-box();
				padding: 10px 25px;
				border-radius: 32px;
				margin: 0;
				svg {
					margin-left: 10px;
				}
				&:hover {
					@apply bg-secondColor-900;
					@apply text-white;
					border-radius: 32px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent;
				}
			}
			.exp-imp-btn {
				background-color: white;
			}
			.exp-imp-tab {
				width: 100%;
				@include center-box(flex-start, center);
				@apply bg-secondColor-200;
				padding: 20px;
				.select-export {
					margin-right: 20px;
					cursor: pointer;
					padding: 10px;
					border: 1px solid #ccc;
					border-radius: 4px;
					font-size: 16px;
					color: #333;
					border-radius: 8px;
					&:focus {
						box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
					}
				}
			}
		}
		.search-input {
			@include center-box(flex-start, center);
			width: 60%;
			max-width: 500px;
			border: 1px solid #dadada;
			border-radius: 5px;
			padding: 13px 20px;

			input {
				margin-left: 10px;
				font-size: 14px;
				&::placeholder {
					@apply text-secondColor-900;
				}
			}
		}
	}

	// filters
	.filters-options {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
		padding: 15px;
		background-color: #f5f5f5;
		border-radius: 8px;

		input[type='text'],
		input[type='number'],
		select {
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 16px;
			color: #333;

			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}
		}

		.filter-btn {
			padding: 10px 20px;
			@apply bg-secondColor-900;
			color: #fff;
			border: none;
			border-radius: 16px;
			cursor: pointer;
			transition: background-color 0.2s;
			margin: 0;
			&:hover {
				@apply bg-secondColor-700;
				@apply text-white;
				border-radius: 16px;
				border-style: solid;
				border-color: transparent;
				padding: 10px 20px;
				border: none;
			}
		}
	}

	.dashboard-content {
		margin-top: 50px;
		@extend %main-tables;
		.pagination-controls {
			width: 100%;
			.prevNext {
				width: 100%;
				max-width: 200px;
				@include center-box(space-between, center);
				button {
					@include center-box();
					svg {
						margin: 0 10px;
					}
				}
			}
			.page-size-select {
				margin-top: 10px;
				select:hover {
					cursor: pointer;
				}
			}
		}
		.btns-selection {
			@include center-box(flex-start, center);
			margin-bottom: 20px;
			.action-btns {
				width: auto;
			}
		}

		td {
			.btn-details {
				@extend %transparent-btn;
				@include center-box();
				svg {
					margin-left: 10px;
				}
			}
		}
		.select-by-name {
			cursor: pointer;
			.inline-icon {
				@include center-box(flex-start, center);

				svg {
					margin-right: 10px;
				}
			}
			&:hover {
				@apply text-secondColor-500;
				@apply bg-secondColor-100;
			}
		}
		.user-list {
			.inline-icon {
				@include center-box(flex-start, center);

				svg {
					margin-right: 10px;
				}
			}
		}
		.tab-information {
			@extend %detail-blocks;

			.content-block {
				padding-top: 20px;
				.data-content {
					padding: 5px 10px;
					border-bottom: solid 1px #e0e0e0;
					@include center-box(flex-start, center);

					strong {
						width: 120px;
					}
				}
			}
		}
	}
}
