.login-page {
	.logo-login {
		width: 100%;
		height: auto;
		max-width: 166px;
	}
	.login-form {
		border-radius: 10px;
		margin-top: 50px;
		margin-bottom: 20px;
		padding: 25px 34px 0;
		width: 100%;
		max-width: 348px;
		input {
			line-height: 14px;
			padding: 3px 5px;
		}
	}
	.form-error {
		@include center-box(flex-start, center);
		width: 100%;
		svg {
			margin-right: 10px;
		}
	}
	.align-btn {
		text-align: center;
		.btn-login {
			@extend %transparent-btn;
			@apply bg-redColor-500;
			margin: 20px auto 0;
			color: white;
			width: 167px;
			&:hover {
				@apply bg-redColor-700;
			}
		}
	}
}
