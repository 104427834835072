@import 'react-clock/dist/Clock.css';
.dashboard-page {
	.summary-btns {
		@include center-box(space-between, flex-start);
		width: 100%;
		li {
			@include center-box(flex-start, flex-start);
			margin-right: 10px;
			max-width: 259px;
			min-width: 197px;
			margin-bottom: 10px;
			.sb {
				@apply bg-secondColor-200;
				@include center-box(space-between, center);
				border-radius: 10px;
				padding: 15px 25px;
				width: 100%;
				max-width: 259px;
				svg {
					@apply text-secondColor-600;
					margin-right: 10px;
				}
				p {
					@include center-box(flex-start, space-between);
					flex-direction: column;
					line-height: 17px;

					strong {
						width: 100%;
						max-width: 91px;
						margin-bottom: 6px;
					}
				}
			}
		}
	}
	.searchbar {
		margin-top: 38px;
		width: 100%;
		@include center-box(space-between, center);
		.filters {
			@include center-box(flex-start, center);
			button {
				margin-right: 10px;
				border-radius: 32px;
				border: 1px solid #dadada;
				@include center-box(flex-start, center);
				padding: 13px 20px;
				svg {
					margin-left: 10px;
				}
				&:hover {
					@apply bg-secondColor-900;
					@apply text-white;
					border-radius: 32px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent;
				}
			}
		}
		.action-btns {
			.btn-new-user,
			.exp-imp-btn {
				@extend %transparent-btn;
				@include center-box();
				padding: 10px 25px;
				border-radius: 32px;
				margin: 0;
				svg {
					margin-left: 10px;
				}
				&:hover {
					@apply bg-secondColor-900;
					@apply text-white;
					border-radius: 32px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent;
				}
			}
			.exp-imp-btn {
				background-color: white;
			}
			.exp-imp-tab {
				width: 100%;
				@include center-box(flex-start, center);
				@apply bg-secondColor-200;
				padding: 20px;
				.select-export {
					margin-right: 20px;
					cursor: pointer;
					padding: 10px;
					border: 1px solid #ccc;
					border-radius: 4px;
					font-size: 16px;
					color: #333;
					border-radius: 8px;
					&:focus {
						box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
					}
				}
			}
		}
		.search-input {
			@include center-box(flex-start, center);
			width: 60%;
			max-width: 500px;
			border: 1px solid #dadada;
			border-radius: 5px;
			padding: 13px 20px;

			input {
				margin-left: 10px;
				font-size: 14px;
				&::placeholder {
					@apply text-secondColor-900;
				}
			}
		}
	}

	// filters
	.filters-options {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
		padding: 15px;
		background-color: #f5f5f5;
		border-radius: 8px;

		input[type='text'],
		input[type='number'],
		select {
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			font-size: 16px;
			color: #333;

			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}
		}

		.filter-btn {
			padding: 10px 20px;
			@apply bg-secondColor-900;
			color: #fff;
			border: none;
			border-radius: 16px;
			cursor: pointer;
			transition: background-color 0.2s;
			margin: 0;
			&:hover {
				@apply bg-secondColor-700;
				@apply text-white;
				border-radius: 16px;
				border-style: solid;
				border-color: transparent;
				padding: 10px 20px;
				border: none;
			}
		}
	}
	.dashboardList {
		max-width: 700px;
		margin-top: 0;
		border: 1px solid #ccc;
		padding: 10px;
		border-radius: 3px;
		.searchbar {
			margin-top: 0;
		}
	}

	.dashboard-content {
		// margin-top: 50px;
		@extend %main-tables;
		.pagination-controls {
			width: 100%;
			.prevNext {
				width: 100%;
				max-width: 200px;
				@include center-box(space-between, center);
				button {
					@include center-box();
					svg {
						margin: 0 10px;
					}
				}
			}
			.page-size-select {
				margin-top: 10px;
				select:hover {
					cursor: pointer;
				}
			}
		}
		.btns-selection {
			@include center-box(flex-start, center);
			margin-bottom: 20px;
			.action-btns {
				width: auto;
			}
		}
		.action-btns {
			width: 100%;
			@include center-box(flex-end, center);

			.btn-new-user {
				@extend %transparent-btn;
				@include center-box();
				margin: 0;
				svg {
					margin-left: 10px;
				}
			}
		}
		td {
			.btn-details {
				@extend %transparent-btn;
				@include center-box();
				svg {
					margin-left: 10px;
				}
			}
		}
		.select-by-name {
			cursor: pointer;
			.client-name {
				text-transform: capitalize;
				text-align: left;
			}

			.inline-icon {
				@include center-box(flex-start, center);

				svg {
					margin-right: 10px;
				}
			}
			&:hover {
				@apply text-secondColor-500;
				@apply bg-secondColor-100;
			}
			.statusDot {
				@include center-box();
				padding: 29px 0px;
				img {
					width: 15px;
					height: 15px;
					margin-right: 10px;
				}
			}
		}
		.user-list {
			.profilePict {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right: 10px;
				overflow: hidden;
				@include center-box();
				span {
					width: 100%;
					height: 100%;
					display: block;
					background-size: cover;
					background-position: center;
				}
			}
			.inline-icon {
				@include center-box(flex-start, center);

				svg {
					margin-right: 10px;
				}
			}
		}
		.tab-information {
			@extend %detail-blocks;

			.content-block {
				padding-top: 20px;
				.data-content {
					padding: 5px 10px;
					border-bottom: solid 1px #e0e0e0;
					@include center-box(flex-start, center);

					strong {
						width: 120px;
					}
				}
			}
		}
	}
	.dashboard-graph-content {
		@include center-box(flex-start, flex-start);
		padding-bottom: 50px;
		max-width: 1200px;
		.chart-container {
			width: 50%;
			padding: 20px;
			@include center-box(center, center);

			h2 {
				margin-bottom: 20px;
				width: 100%;
				text-align: center;
				font-size: 24px;
			}
		}
		.order-status-bar {
			@include center-box(flex-start, flex-start);
			padding: 20px;

			.order-title {
				margin-bottom: 20px;
				width: 100%;
				text-align: left;
				font-size: 24px;
			}
			.order-status-list {
				@include center-box(flex-start, center);
				max-width: 450px;
				margin-right: 120px;
				@media (max-width: 768px) {
					margin-right: 0;
				}

				li {
					@include center-box(flex-start, center);
					margin: 10px;
					width: 100%;
					max-width: 200px;
					.quote-item {
						@include center-box(space-between, center);
						width: 100%;
						border-bottom: 1px solid #e0e0e0;
						.quote-label {
							margin-right: 15px;
							width: 100%;
							max-width: 150px;
						}
						.quote-value {
						}
					}
				}
			}
		}
	}
	.dashboard-graph-internal-cont {
		@include center-box(center, center);
		.chart-container {
			width: 50%;
			padding: 20px;
			@include center-box(center, center);
			margin: auto 10px;
			h2 {
				margin-bottom: 20px;
			}
		}
	}
	.formSubmitFile {
		@extend %general-fields;
		@include center-box(flex-start, center);
		margin-bottom: 30px;
		label {
			width: 25%;
		}
		input {
			width: 75%;
			padding: 3px;
			margin-bottom: 10px;
		}
		textarea {
			width: 75%;
			padding: 3px;
		}
		// progress bar to upload the file
		.progress-bar {
			margin-bottom: 20px;
			padding: 3px;
			border: 1px solid #ccc;
			.progress-bar-fill {
				background-color: #f00;
				height: 20px;
				width: 0;
			}
		}
		.btn-save {
			@extend %red-btn;
			@include center-box();
			margin-top: 20px;
			color: red;
			svg {
				margin-left: 10px;
			}
		}
	}
	.notesList {
		@include center-box(flex-start, center);
		background-color: white;
		margin: 10px auto;
		padding: 10px 20px;
		width: 100%;
		border-radius: 8px;
		.notes-item-title {
			font-size: 14px;
			font-weight: 600;
			width: 100%;
			@apply text-secondColor-700;
		}
		.notes-item-value {
			width: 100%;
			font-size: 16px;
			font-weight: 400;
			@apply text-secondColor-700;
		}
		.download-array {
			width: 100%;
			@include center-box(flex-start, center);
			border-top: 1px solid #ccc;
			margin-top: 10px;
			padding-top: 10px;
			.notes-item-attachment {
				.btn-download-file {
					position: relative;
					display: inline-block;
					width: 60px;
					@include center-box();
					margin-top: 20px;
					margin: 3px;
					padding: 3px;
					border: 1px solid red;
					border-radius: 25px;
					svg {
						color: red;
					}
					&:hover {
						background-color: red;
						svg {
							color: white;
						}
					}
				}
			}
		}
	}
}
