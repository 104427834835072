.new-user-details-panel {
	// Example styles for a slide-out panel
	position: fixed;
	overflow-y: scroll;
	right: 0;
	top: 80px;
	padding-bottom: 80px;
	width: 100%; // Adjust as necessary
	height: 100%;
	background: rgba(#fff, 0.7);

	// transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	@include center-box(flex-end, flex-start);

	// This assumes you toggle a class to show/hide the panel
	&.show {
		transform: translateX(0);
	}
	.details-panel {
		-webkit-box-shadow: -10px 0 10px 0 #dedede;
		box-shadow: -10px 0 10px 0 #dedede;
		width: 100%;
		height: 100vh;
		background: #fff;
		padding: 26px;
		@media (max-width: 900px) {
			width: 100%;
		}
		.details-number {
			margin-bottom: 50px;
			@include center-box(space-between, flex-start);
			.big-title {
				font-size: 24px;
				font-weight: 600;
				@apply text-secondColor-700;
			}
			.block-left {
				@include center-box(flex-start, center);
				flex-direction: column;
				.details-number-title {
					font-size: 24px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.details-number-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
			.block-right {
				@include center-box(flex-end, center);
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
			}
		}
		.progress-guide {
			margin-bottom: 50px;
			@include center-box(space-between, center);
			.progress-guide-item {
				@include center-box(flex-start, center);
				flex-direction: column;
				.progress-guide-item-title {
					font-size: 14px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.progress-guide-item-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
		}
		.tab-information {
			@extend %detail-blocks;
			max-width: 1000px;
			margin: 0 auto;
			.content-block {
				padding-top: 20px;
				.data-content {
					padding: 5px 10px;
					border-bottom: solid 1px #e0e0e0;
					@include center-box(flex-start, center);

					strong {
						width: 120px;
					}
				}
			}
		}
		.sale-information {
			@extend %detail-blocks;
		}
		.client-information {
			@extend %detail-blocks;
		}
		.structure-details {
			@extend %detail-blocks;
		}
		.notes-block {
			@extend %detail-blocks;
		}
		.installation-permits {
			@extend %detail-blocks;
		}

		.redBtn {
			@apply bg-redColor-500;
			color: #fff;
			border-radius: 5px;
			padding: 10px 20px;
			font-size: 14px;
			font-weight: 600;
			border: none;
			cursor: pointer;
			margin-top: 20px;
			&:hover {
				@apply bg-redColor-700;
				color: #fff;
			}
		}
	}
}
.redBtn {
	@apply bg-redColor-500;
	color: #fff;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 600;
	border: none;
	cursor: pointer;
	margin-top: 20px;
	&:hover {
		@apply bg-redColor-700;
		color: #fff;
	}
}
