// SCSS SYNTAX
// %message-shared {
//     border: 1px solid #ccc;
//     padding: 10px;
//     color: #333;
//   }

//   .message {
//     @extend %message-shared;
//   }

%main-tables {
	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border-radius: 5px;
		margin-bottom: 20px;
		thead {
			tr {
				th {
					padding: 15px 20px;
					font-size: 14px;
					font-weight: 700;
					@apply text-secondColor-600;
					text-align: center;
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 15px 20px;
					font-size: 14px;
					font-weight: 400;
					@apply text-secondColor-800;
					text-align: center;
					border-bottom: 1px solid #eaeaea;
					&:first-child {
						border-top-left-radius: 5px;
						text-transform: capitalize;
					}
					&:last-child {
						border-top-right-radius: 5px;
					}
				}
			}
		}
	}
}

%big-title {
	@apply text-secondColor-700;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 20px;
}

%transparent-btn {
	border-radius: 10px;
	border-width: 1px;
	border-style: solid;
	@apply border-secondColor-400;
	padding: 15px 29px;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	&:hover {
		@apply bg-secondColor-900;
		@apply text-white;
		border-radius: 10px;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
	}
}

%transparent-small-btn {
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	@apply border-secondColor-400;
	padding: 5px 10px;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	&:hover {
		@apply bg-secondColor-900;
		@apply text-white;
		border-radius: 10px;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
	}
}

%red-btn {
	border-radius: 10px;
	border-width: 2px;
	border-style: solid;
	@apply border-redColor-400;
	padding: 10px 29px;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	&:hover {
		@apply bg-redColor-600;
		@apply text-white;
		border-radius: 10px;
		border-width: 2px;
		border-style: solid;
		border-color: transparent;
	}
}

%transparent-edit-btn {
	border-radius: 10px;
	border-width: 2px;
	border-style: solid;
	@apply border-secondColor-400;
	padding: 10px 29px;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	&:hover {
		@apply bg-secondColor-900;
		@apply text-white;
		border-radius: 10px;
		border-width: 2px;
		border-style: solid;
		border-color: transparent;
	}
}

%detail-blocks {
	width: 100%;
	@apply bg-secondColor-100;
	padding: 25px 34px;
	border-radius: 10px;
	margin-bottom: 22px;
	.title-block {
		@include center-box(space-between, center);
		cursor: pointer;
		.title-h2 {
			@apply text-secondColor-700;
			font-weight: 600;
			font-size: 21px;
			text-align: left;
			padding-bottom: 20px;
		}
		button {
			padding: 10px;
		}
	}
}

%general-fields {
	label {
		@apply text-secondColor-700;
		margin-bottom: 10px;
	}
	.reg-input {
		background: white;
		@apply border-secondColor-300;
		@apply text-secondColor-700;
		padding: 10px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
		border-width: 1px;
		border-style: solid;
		&:focus {
			@apply border-secondColor-500;
		}
	}
	.reg-textarea {
		background: white;
		@apply border-secondColor-300;
		@apply text-secondColor-700;
		padding: 10px 15px;
		border-radius: 5px;
		margin-bottom: 20px;
		border-width: 1px;
		border-style: solid;
		&:focus {
			@apply border-secondColor-500;
		}
	}
}
