.clockin-page {
	.summary-btns {
		@include center-box(space-between, flex-start);
		width: 100%;
		li {
			@include center-box(flex-start, flex-start);
			margin-right: 10px;
			max-width: 259px;
			min-width: 197px;
			margin-bottom: 10px;
			.sb {
				@apply bg-secondColor-200;
				@include center-box(space-between, center);
				border-radius: 10px;
				padding: 15px 25px;
				width: 100%;
				max-width: 259px;
				svg {
					@apply text-secondColor-600;
					margin-right: 10px;
				}
				p {
					@include center-box(flex-start, space-between);
					flex-direction: column;
					line-height: 17px;

					strong {
						width: 100%;
						max-width: 91px;
						margin-bottom: 6px;
					}
				}
			}
		}
	}
	.searchbar {
		margin-top: 38px;
		width: 100%;
		@include center-box(space-between, center);
		.filters {
			width: 30%;
			button {
				border-radius: 32px;
				border: 1px solid #dadada;
				@include center-box(flex-start, center);
				padding: 13px 20px;
				svg {
					margin-left: 10px;
				}
			}
		}
		.search-input {
			@include center-box(flex-start, center);
			width: 60%;
			max-width: 500px;
			border: 1px solid #dadada;
			border-radius: 5px;
			padding: 13px 20px;

			input {
				margin-left: 10px;
				font-size: 14px;
				&::placeholder {
					@apply text-secondColor-900;
				}
			}
		}
	}

	.clockin-content {
		margin-top: 20px;
		@extend %main-tables;
		border: none;
		.pagination-controls {
			width: 100%;
			.prevNext {
				width: 100%;
				max-width: 200px;
				@include center-box(space-between, center);
				button {
					@include center-box();
					svg {
						margin: 0 10px;
					}
				}
			}
			.page-size-select {
				margin-top: 10px;
				select:hover {
					cursor: pointer;
				}
			}
		}
		.btns-selection {
			@include center-box(flex-start, center);
			margin-bottom: 20px;
			.action-btns {
				width: auto;
			}
		}
		.action-btns {
			width: 100%;
			@include center-box(flex-end, center);

			.btn-new-user {
				@extend %transparent-btn;
				@include center-box();
				margin: 0;
				svg {
					margin-left: 10px;
				}
			}
		}
		td {
			.btn-details {
				@extend %transparent-btn;
				@include center-box();
				svg {
					margin-left: 10px;
				}
			}
		}
		.title-time {
			text-align: left;
			strong {
				font-size: 20px;
				margin-right: 10px;
			}
		}

		.today-bar {
			@apply bg-secondColor-200;
			border: 1px solid #dadada;
			border-radius: 5px;
			padding: 10px 20px;
			margin: 20px auto;
			@include center-box(flex-end, center);
			.btns-clockin {
				@include center-box(flex-end, center);
				max-width: 250px;
				.btn-clockin {
					border: 1px solid red;
					@include center-box();
					padding: 5px 10px;
					@include center-box();
					font-size: 14px;
					color: red;
					border-radius: 5px;
					margin-right: 10px;
					background-color: white;
					svg {
						margin-left: 5px;
					}
					&:hover {
						background-color: red;
						color: white;
					}
				}
				.btn-clockout {
					border: 1px solid red;
					@include center-box();
					padding: 5px 10px;
					@include center-box();
					font-size: 14px;
					background-color: red;
					color: white;
					border-radius: 5px;
					margin-right: 10px;
					svg {
						margin-left: 5px;
					}
					&:hover {
						color: red;
						background-color: white;
					}
				}
			}
		}
		.table-records {
			margin: 20px 0;
			thead {
				background-color: #f9f9f9;
			}
			tbody {
				tr {
					background-color: white;
					&:nth-child(odd) {
						background-color: #f9f9f9;
					}
					td {
						padding: 3px;
						.btn-details {
							@extend %transparent-btn;
							@include center-box();
							svg {
								margin-left: 10px;
							}
						}
					}
				}
			}
		}

		.box-attendance {
			@include center-box(space-evenly, center);
			width: 100%;
			.box-record {
				width: 20%;
				text-align: center;
				@include center-box(flex-start, center);
				h3 {
					margin: 3px auto;
				}
			}
		}
		.user-information {
			@extend %detail-blocks;
			.title-h2 {
				padding: 10px !important;
			}
			table {
				background-color: white;
			}
			p {
				margin-bottom: 20px;
				border-bottom-style: solid;
				border-bottom-width: 1px;
				@apply border-secondColor-300;
				strong {
					@apply text-secondColor-700;
					margin-right: 20px;
				}
			}
		}
	}
}
