// Define base styles for the progress bar container
.progress-bar-container {
	display: flex;
	align-items: center;
	width: 100%;
}

// Styles for each status item in the progress bar
.progress-bar-item {
	display: flex;
	width: 100%;
	align-items: center;
	position: relative; // Important for positioning the line correctly

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		height: 2px;
		background-color: #ddd; // Default line color
		width: calc(100% - 10px); // Adjust width based on your needs
	}
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		height: 2px;
		background-color: #ddd; // Default line color
		width: calc(100% - 10px); // Adjust width based on your needs
	}
	&:last-child:after {
		display: none; // Hide the line after the last item
	}
	&:last-child:before {
		display: none; // Hide the line after the last item
	}
}

// Styles for the circle indicator
.circle-indicator {
	position: relative;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px; // Adjust spacing
}

// Text label styles
.status-label {
	width: 70px;
	position: absolute;
	text-align: center;
	font-size: 14px;
	padding-top: 65px; // Adjust as needed
}

// Conditional styles for active and inactive states
.active {
	.circle-indicator {
		background-color: #f00; // Active color
	}
	&:after {
		background-color: #f00; // Active line color
	}
	&:before {
		background-color: #f00; // Active line color
	}
}

.inactive {
	.circle-indicator {
		background-color: #ccc; // Inactive color
	}
	&:after {
		background-color: #ccc; // Inactive line color
	}
	&:before {
		background-color: #ccc; // Inactive line color
	}
}
