.btn-tab-selection {
	@extend %transparent-small-btn;
	@include center-box();
	margin: 5px;
	svg {
		margin-left: 10px;
	}
}

.btn-edit-item {
	@extend %transparent-edit-btn;
	@include center-box();
	svg {
		margin-left: 10px;
	}
}

#mobile-display {
	@media (max-width: 768px) {
		display: none;
	}
}

// modal

.modal {
	position: fixed;
	@include center-box();
	width: 100%;
	height: 100%;
	padding: 15px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 99999;

	.modal-content {
		width: 50%;
		min-height: 250px;
		background-color: white;
		border-radius: 10px;
		@include center-box();
		.btncontainer {
			@include center-box();
			margin-top: 10px;
			width: 100%;
			button {
				margin: 0 10px;
			}
			.yesBtn {
				min-width: 90px;
				@extend %transparent-small-btn;
				border: 1px solid red;
				background-color: red;
				color: white;
				margin: 10px;
			}
			.noBtn {
				margin: 10px;
				min-width: 90px;
				@extend %transparent-small-btn;
				border: 1px solid green;
				background-color: green;
				color: white;
			}
		}
	}
}
