.order-details-panel {
	// Example styles for a slide-out panel
	position: fixed;
	overflow-y: scroll;
	right: 0;
	top: 80px;
	padding-bottom: 80px;
	width: 100%; // Adjust as necessary
	height: 100%;
	background: rgba(#fff, 0.7);

	// transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	@include center-box(flex-end, flex-start);

	// This assumes you toggle a class to show/hide the panel
	&.show {
		transform: translateX(0);
	}
	.details-panel {
		-webkit-box-shadow: -10px 0 10px 0 #dedede;
		box-shadow: -10px 0 10px 0 #dedede;
		width: 70%;
		background: #fff;
		padding: 26px;
		@media (max-width: 900px) {
			width: 100%;
		}
		.order-number {
			margin-bottom: 50px;
			@include center-box(space-between, flex-start);
			.block-left {
				@include center-box(flex-start, center);
				flex-direction: column;
				.order-number-title {
					font-size: 24px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.order-number-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
			.block-right {
				@include center-box(flex-end, center);
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
			}
		}
		.progress-guide {
			margin-bottom: 50px;
			@include center-box(space-between, center);
			.progress-guide-item {
				@include center-box(flex-start, center);
				flex-direction: column;
				.progress-guide-item-title {
					font-size: 14px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.progress-guide-item-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
		}
		.sale-information {
			@extend %detail-blocks;
		}
		.tab-information {
			@extend %detail-blocks;

			.block-subtitle {
				padding-top: 25px;
				.title-h2 {
					font-size: 16px;
					font-weight: 700;
					margin: 0;
					@apply text-secondColor-700;
				}
			}
			.content-box {
				.content-body {
					@include center-box(space-between, flex-start);
					@apply border-b border-secondColor-300;
					border-bottom-width: 1px;
					border-style: solid;
					padding: 10px 5px;

					div {
						width: 60%;
						a {
							@apply text-redColor-500;
							text-decoration: underline;
						}
						&:first-child {
							margin-right: 10px;
							width: 35%;
						}
					}
				}
			}
			.content-box-notes {
				.content-body {
					@include center-box(flex-start, center);
					@apply border-b border-secondColor-300;
					border-bottom-width: 1px;
					border-style: solid;
					padding: 10px 5px;
					margin-bottom: 20px;
					.date-box {
						@apply text-secondColor-700;
						margin-right: 10px;
						width: 100%;
					}
					.note-box {
						@apply text-secondColor-700;
					}
				}
			}
		}
		.structure-details {
			@extend %detail-blocks;
			.price-cont {
				.price-subtitle {
					font-size: 16px;
					font-weight: 700;
					max-width: 650px;
					margin: 0 auto 20px;
					@apply text-secondColor-700;
				}
				.price-summary {
					max-width: 650px;
					margin: 0 auto 20px;
					// @include center-box(flex-start, center);
					tr {
						width: 100%;
						min-width: 380px;
						@include center-box(space-between, center);
						&:nth-child(odd) {
							background-color: #fff;
						}
						td {
							text-align: left;
						}
					}
				}
			}
			.price-total-container {
				.price-total {
					max-width: 650px;
					margin: 0 auto;
					@include center-box(flex-end, center);
					.price-total-title {
						font-size: 16px;
						font-weight: 700;
						margin: 0 20px 0 0;
						padding: 2px;
						@apply text-secondColor-700;
					}
					.price-total-value {
						font-size: 16px;
						font-weight: 700;
						@apply text-secondColor-700;
						margin: 0 20px 0 0;
						min-width: 90px;
						text-align: right;
					}
				}
			}
		}
		.notes-block {
			@extend %detail-blocks;
		}
		.installation-permits {
			@extend %detail-blocks;
			h2 {
				font-size: 16px;
				font-weight: 700;
				margin: 0;
				@apply text-secondColor-700;
			}
			p {
				margin-bottom: 16px;
			}
		}
	}
}

.close-btn-2 {
	@include center-box();
	@apply text-gray-900;
	width: 40px;
	height: 40px;
	margin: 10px 10px 10px auto;
	border-radius: 8px;
	border: 1px solid #ccc;
	padding: 5px;
	cursor: pointer;
	&:hover {
		@apply text-secondColor-500;
	}
}
