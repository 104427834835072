.new-order-page {
	// Example styles for a slide-out panel
	position: fixed;
	overflow-y: scroll;
	right: 0;
	top: 80px;
	padding-bottom: 80px;
	width: 100%; // Adjust as necessary
	height: 100%;
	background: rgba(#fff, 0.7);

	// transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	@include center-box(flex-end, flex-start);

	// This assumes you toggle a class to show/hide the panel
	&.show {
		transform: translateX(0);
	}

	// to Select a client
	.action-btns-container {
		max-width: 1000px;
		width: 70%;
		background: #fff;
		margin: 120px auto 10px;
		@media (max-width: 900px) {
			width: 100%;
		}
		.action-btns {
			@include center-box(flex-end, center);
			.btn-new-user {
				@extend %transparent-btn;
				@include center-box();
				padding: 10px 25px;
				border-radius: 32px;
				margin: 0;
				&:hover {
					@apply bg-secondColor-900;
					@apply text-white;
					border-radius: 32px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent;
				}
			}
		}
	}

	// Main container
	.details-panel,
	.new-details-panel {
		-webkit-box-shadow: -10px 0 10px 0 #dedede;
		box-shadow: -10px 0 10px 0 #dedede;

		// heigth full screen
		height: auto;
		background: #fff;
		padding: 0 26px 26px;
		padding-bottom: 100px;
		position: relative;
		min-height: 100vh;
		height: auto;
		width: 100%;
		@media (max-width: 900px) {
			width: 100%;
		}

		.details-number {
			max-width: 1000px;
			margin: 0 auto 50px;
			@include center-box(space-between, flex-start);

			.block-left {
				@include center-box(flex-start, center);

				// .details-number-title {
				// 	font-size: 24px;
				// 	font-weight: 600;
				// 	width: 100%;
				// 	@apply text-secondColor-700;
				// }
			}
			.details-number-title {
				font-size: 24px;
				font-weight: 600;
				@apply text-secondColor-700;
			}
			.block-right {
				@include center-box(flex-end, center);
				padding-right: 60px;
				@media (max-width: 900px) {
					padding-right: 10px;
				}
				.btn-delete-item {
					@extend %red-btn;
					@apply text-redColor-400;
					@include center-box();
					margin-right: 10px;
					svg {
						margin-left: 10px;
					}
				}
				.btn-edit-item {
					@extend %transparent-edit-btn;
					@include center-box();
					svg {
						margin-left: 10px;
					}
				}
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
			}
		}

		.details-number-quote {
			width: 100%;
			@include center-box(space-between, flex-start);
			position: fixed;
			background-color: white;
			padding: 20px 50px 26px;
			z-index: 1;
			@media (max-width: 900px) {
				padding: 10px;
			}
			.block-left {
				@include center-box(flex-start, center);

				// .details-number-title {
				// 	font-size: 24px;
				// 	font-weight: 600;
				// 	width: 100%;
				// 	@apply text-secondColor-700;
				// }
			}
			.details-number-title {
				font-size: 24px;
				font-weight: 600;

				@apply text-secondColor-700;
			}
			.block-right {
				@include center-box(flex-end, center);
				.btn-delete-item {
					@extend %red-btn;
					@apply text-redColor-400;
					@include center-box();
					margin-right: 10px;
					svg {
						margin-left: 10px;
					}
				}
				.btn-edit-item {
					@extend %transparent-edit-btn;
					@include center-box();
					svg {
						margin-left: 10px;
					}
				}
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
			}
		}
		.margin-top {
			margin-top: 130px !important;
		}
		.tab-information {
			@extend %detail-blocks;
			max-width: 1000px;
			margin: 20px auto 20px;

			&.notes-special-block {
				margin-top: 50px;
			}
			p {
				margin-bottom: 10px;
				margin-top: 10px;
				// border-bottom-style: solid;
				// border-bottom-width: 1px;
				// @apply border-secondColor-300;
				strong {
					@apply text-secondColor-700;
					margin-right: 20px;
				}
			}
			.new-order-form {
				.tab-subtitle {
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 10px;
					@apply text-secondColor-700;
				}
				.instructions-p {
					margin-bottom: 50px;
				}

				.list-options {
					.radio-button-list {
						@include center-box(flex-start, center);
						// li:first-child {
						// 	margin-right: 25px;
						// 	margin-bottom: 20px;
						// 	font-weight: 700;
						// }

						.radio-option {
							display: flex;
							align-items: center;
							margin-bottom: 20px; // Adjust as needed
							margin-right: 25px;
							input[type='radio'] {
								appearance: none;
								margin-right: 10px; // Space between radio button and label
								width: 20px; // Width of the radio button
								height: 20px; // Height of the radio button
								border: 2px solid rgb(194, 194, 194); // Border color
								border-radius: 2px; // Border radius for square edges
								position: relative;
								cursor: pointer;

								&:before {
									content: '';
									display: block;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 12px; // Width of the inner square
									height: 12px; // Height of the inner square
									background: transparent;
									transition: background 0.2s;
								}

								&:checked:before {
									background: red; // Inner square color when selected
								}
								// if checked, select the label>selected-value and display it, selected-value is hidden by default and is located outside the radio button
								&:checked ~ label .selected-value {
									display: block;
								}
							}

							label {
								cursor: pointer;
								margin-right: 5px; // Space between radio button and label
								min-width: 50px;
								.selected-value {
									display: none;
								}
							}
						}
					}
				}
				// create the same style for the checkbox
				.checkbox-button-list {
					@include center-box(flex-start, center);
					.checkbox-option {
						display: flex;
						align-items: center;
						margin-bottom: 20px; // Adjust as needed
						margin-right: 25px;
						&.check-tcolm {
							width: 40%;
							label {
								width: 70%;
							}
						}
						&.check-tcolm-lg {
							width: 100%;
							label {
								width: 70%;
							}
						}
						input[type='checkbox'] {
							appearance: none;
							margin-right: 10px; // Space between radio button and label
							width: 20px; // Width of the radio button
							height: 20px; // Height of the radio button
							border: 2px solid rgb(194, 194, 194); // Border color
							border-radius: 2px; // Border radius for square edges
							position: relative;
							cursor: pointer;

							&:before {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 12px; // Width of the inner square
								height: 12px; // Height of the inner square
								background: transparent;
								transition: background 0.2s;
							}

							&:checked:before {
								background: red; // Inner square color when selected
							}
							// if checked, select the label>selected-value and display it, selected-value is hidden by default and is located outside the radio button
							&:checked ~ label .selected-value {
								display: block !important;
							}
						}

						label {
							cursor: pointer;
							margin-right: 5px; // Space between radio button and label
							min-width: 50px;
							.selected-value {
								display: none;
							}
						}
					}
				}
				.field-section-full {
					@extend %general-fields;
					@include center-box(flex-start, center);
					margin-bottom: 10px;
					label {
						width: 25%;
					}
					input,
					textarea {
						width: 75%;
						padding: 3px;
						margin-bottom: 10px;
					}
					.notes-block {
						width: 100%;
						.notes-date {
							font-size: 16px;
							font-weight: 700;
							margin-bottom: 10px;
							@apply text-secondColor-700;
						}
						.notes-content {
							margin-bottom: 20px;
							p {
								margin-bottom: 10px;
								strong {
									@apply text-secondColor-700;
									margin-right: 20px;
								}
							}
						}
					}
				}
				.field-section-one-half {
					@extend %general-fields;
					@include center-box(flex-start, center);
					margin-bottom: 10px;
					label {
						width: 25%;
						max-width: 90px;
					}
					input,
					textarea {
						width: 75%;
						margin-bottom: 10px;
					}
					.p-tab-info {
						border: none;
					}
					.select-input {
						cursor: pointer;
						padding: 10px;
					}
				}

				.field-section-half {
					@extend %general-fields;
					@include center-box(space-between, center);
					.inp-col {
						@include center-box(flex-start, center);
						width: 48%;
						.p-tab-info {
							border: none;
						}

						label {
							width: 35%;
							padding: 3px 3px 3px 0;
						}
						input,
						textarea,
						select {
							width: 65%;
							margin-bottom: 10px;
							padding: 3px;
						}
						select {
							cursor: pointer;
							padding: 10px;
						}
						.p-tab-active {
							@include center-box(flex-start, center);
							border: none;
							strong {
								margin-right: 30px;
							}
						}
					}
				}
				.field-section-inputs {
					@extend %general-fields;
					@include center-box(space-between, center);

					.inp-col-size {
						@include center-box(flex-start, center);
						// flex-direction: column;
						&:first-child {
							width: 100%;
							max-width: 150px;
							text-align: left !important;
						}
						h3 {
							width: 100%;
							font-size: 16px;
							font-weight: 700;
							margin-bottom: 10px;
							@apply text-secondColor-700;
						}

						label {
							width: 100%;
							font-size: 14px;
							margin-bottom: 2px;
						}
						input,
						textarea,
						select {
							width: 100%;
							margin-bottom: 10px;
							cursor: pointer;
							text-align: center;
							max-width: 120px;
							padding: 10px 5px;
							border: 1px solid #ccc;
							border-radius: 8px;
						}
						select {
							padding: 10px;
						}
						.p-tab-active {
							@include center-box(flex-start, center);
							border: none;
							strong {
								margin-right: 30px;
							}
						}
					}
				}
				.field-section-inputs-left {
					@extend %general-fields;
					@include center-box(flex-start, center);
					.inp-col-size {
						@include center-box(flex-start, center);
						flex-direction: column;
						margin-right: 150px;
						&:first-child {
							width: 100%;
							max-width: 140px;
							text-align: left !important;
						}
						h3 {
							width: 100%;
							font-size: 16px;
							font-weight: 700;
							margin-bottom: 10px;
							@apply text-secondColor-700;
						}
						label {
							width: 100%;
							font-size: 14px;
							margin-bottom: 2px;
						}
						input,
						textarea,
						select {
							width: 100%;
							margin-bottom: 10px;
							cursor: pointer;
							text-align: center;
							max-width: 120px;
							padding: 10px 5px;
							border: 1px solid #ccc;
							border-radius: 8px;
						}
						select {
							padding: 10px;
						}
						.p-tab-active {
							@include center-box(flex-start, center);
							border: none;
							strong {
								margin-right: 30px;
							}
						}
					}
				}

				.doors-windows-section {
					h3 {
						width: 100%;
						font-size: 16px;
						font-weight: 700;
						margin-bottom: 10px;
						@apply text-secondColor-700;
					}
					.tab-buttons {
						width: 100%;
						@include center-box(space-evenly, center);
						margin-bottom: 20px;
						.tab-button {
							width: 18%;
							min-height: 105px;
							padding: 10px 30px;
							margin: 0;
							border-color: white;
							border-radius: 16px;
							cursor: pointer;
							text-align: center;
							background-color: white;
							font-weight: 300;
							&:hover,
							&.active {
								@apply text-red-500;
								border-radius: 16px;
								border-width: 1px;
								border-style: solid;
								border: red solid 1px;
							}
							.icon-dwtab {
								margin: 5px auto;
							}
						}
					}
					.tab-windows-doors {
						width: 100%;
						.inputs-container {
							@include center-box(flex-start, center);
							margin-bottom: 20px;

							.input-group {
								width: 80%;
								min-height: 154px;
								background-color: #fff;
								border-radius: 16px;
								padding: 10px 20px;
								@include center-box(flex-start, center);
								&:first-child {
									@include center-box();
									width: 18%;
									margin-right: 10px;
									position: relative;
									text-align: center;
									.icon-dwtab {
										margin: 5px auto;
									}
									.remove-item {
										position: absolute;
										top: 5px;
										right: 5px;
										cursor: pointer;
										svg {
											margin-left: 10px;
										}
									}
								}

								.input-selection {
									width: 100%;

									@include center-box(space-between, center);
									.group-title {
										font-size: 16px;
										font-weight: 700;
										margin-bottom: 10px;
										@apply text-secondColor-700;
										max-width: 150px;
									}
									margin-bottom: 10px;
									.fisrt-input {
										width: 38%;
										font-size: 14px;
										margin-right: 10px;
										font-weight: 700;
									}
									.select-group {
										width: 70%;
										padding: 10px;
										border-radius: 8px;
										border: 1px solid #ccc;
									}

									.inp-col-size {
										@include center-box(flex-start, center);

										flex-direction: column;
										&:first-child {
											width: 100%;
											max-width: 150px;
											text-align: left !important;
										}
										h3 {
											font-size: 16px;
											font-weight: 700;
											margin-bottom: 10px;
											@apply text-secondColor-700;
										}

										label {
											width: 100%;
											font-size: 14px;
											margin-bottom: 2px;
										}
										input,
										textarea,
										select {
											width: 100%;
											margin: 0 0px 0px 0;
											cursor: pointer;
											text-align: center;
											max-width: 120px;
											padding: 10px 5px;
											border: 1px solid #ccc;
											border-radius: 8px;
										}
										select {
											padding: 10px;
										}
										.p-tab-active {
											@include center-box(flex-start, center);
											border: none;
											strong {
												margin-right: 30px;
											}
										}
									}
								}
							}
						}
						.door-window-buttons {
							width: 100%;
							@include center-box(space-evenly, center);
							margin-bottom: 20px;
							.door-window-button {
								width: 15%;
								padding: 10px 40px;
								margin: 5px;
								min-height: 110px;
								border-color: white;
								border-width: 1px;
								border-style: solid;
								border-radius: 16px;
								cursor: pointer;
								text-align: center;
								background-color: white;
								font-weight: 300;
								font-size: 12px;
								&:hover,
								&.active {
									@apply text-red-500;
									border-radius: 12px;
									border-width: 1px;
									border-style: solid;
									border: red solid 1px;
								}
								.icon-dwtab {
									margin: 5px auto;
								}
							}
						}
					}
				}
				// scss for colorOptions
				.list-color-options {
					.radio-button-list {
						@include center-box(flex-start, flex-start);
						li:first-child {
							margin-bottom: 10px;
							font-weight: 700;
							width: 100%;
						}

						.radio-color-option {
							display: flex;
							align-items: center;
							margin-bottom: 25px; // Adjust as needed

							flex-direction: column;
							input[type='radio'] {
								appearance: none;
								width: 40px; // Width of the radio button
								height: 40px; // Height of the radio button
								border: 2px solid rgb(194, 194, 194); // Border color
								border-radius: 2px; // Border radius for square edges
								position: relative;
								cursor: pointer;

								&:before {
									content: '';
									display: block;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									width: 12px; // Width of the inner square
									height: 12px; // Height of the inner square
									background: transparent;
									transition: background 0.2s;
								}

								&:checked:before {
									background: transparent; // Inner square color when selected
									border-radius: 50%;
								}
								// if checked, select the label>selected-value and display it, selected-value is hidden by default and is located outside the radio button
								&:checked ~ label .selected-value {
									display: block;
									width: 100%;
								}
							}

							label {
								cursor: pointer;
								margin: 5px auto; // Space between radio button and label
								min-width: 50px;
								font-size: 11px;
								text-align: center;
								position: relative;
								.selected-value {
									display: none;
									position: absolute;
									text-align: center;
									line-height: 11px;
									font-weight: 700;
								}
							}
						}
					}
				}
			}
			.tab-information-block {
				.reg-textarea {
					width: 100%;
					padding: 10px;
					border: 1px solid #ccc;
					border-radius: 8px;
					margin-bottom: 10px;
				}
				.reg-input {
					width: 100%;
					padding: 10px;
					border-radius: 8px;
					margin-bottom: 10px;
				}
				.field-section-full {
					@extend %general-fields;
					@include center-box(flex-start, center);
					margin-bottom: 10px;
					label {
						width: 25%;
					}
					input,
					textarea {
						width: 75%;
						padding: 3px;
						margin-bottom: 10px;
					}
					.installer-dropdown {
						width: 100%;
						label {
							width: 100%;
							display: block;
						}
						select {
							width: 100%;
							padding: 10px;
							border-radius: 8px;
							border: 1px solid #ccc;
							cursor: pointer;
							option {
								padding: 10px;
								cursor: pointer;
							}
						}
					}
					.notes-block {
						width: 100%;
						.notes-date {
							font-size: 16px;
							font-weight: 700;
							margin-bottom: 10px;
							@apply text-secondColor-700;
						}
						.notes-content {
							margin-bottom: 20px;
							p {
								margin-bottom: 10px;
								strong {
									@apply text-secondColor-700;
									margin-right: 20px;
								}
							}
						}
					}
					.delete-file-input {
						@include center-box(flex-start, center);
						font-size: 24px;
						font-weight: 400;
					}
				}

				.input-list-align {
					width: 100%;
					@include center-box(flex-start, center);
					.select-label {
						width: 100%;
						margin-bottom: 10px;
					}
					.search-input {
						width: 100%;
						max-width: 450px;
						padding: 10px;
						border-radius: 8px;
						margin-bottom: 10px;
					}

					.dropdown-list {
						cursor: pointer;
						padding: 10px;
						display: block;
						width: 100%;
						li {
							border-radius: 8px;
							padding: 10px;
							width: 100%;
							&:hover {
								background-color: #f0f0f0;
							}
						}
					}
				}

				.btn-save {
					@extend %red-btn;
					@apply text-redColor-400;
					@include center-box();
					margin-right: 10px;
					svg {
						margin-left: 10px;
					}
				}
				.notesList {
					@include center-box(flex-start, center);
					background-color: white;
					margin: 10px auto;
					padding: 10px 20px;
					width: 100%;
					border-radius: 8px;
					.notes-item-title {
						font-size: 14px;
						font-weight: 600;
						width: 100%;
						@apply text-secondColor-700;
					}
					.notes-item-value {
						width: 100%;
						font-size: 16px;
						font-weight: 400;
						@apply text-secondColor-700;
					}
					.download-array {
						width: 100%;
						@include center-box(flex-start, center);
						border-top: 1px solid #ccc;
						margin-top: 10px;
						padding-top: 10px;
						.notes-item-attachment {
							.btn-download-file {
								position: relative;
								display: inline-block;
								width: 60px;
								@include center-box();
								margin-top: 20px;
								margin: 3px;
								padding: 3px;
								border: 1px solid red;
								border-radius: 25px;
								svg {
									color: red;
								}
								&:hover {
									background-color: red;
									svg {
										color: white;
									}
								}
							}
						}
					}
				}
			}
		}
		.price-cont {
			.price-subtitle {
				font-size: 16px;
				font-weight: 700;
				max-width: 650px;
				margin: 0 auto 20px;
				@apply text-secondColor-700;
			}
			.price-summary {
				max-width: 650px;
				margin: 0 auto 20px;
				// @include center-box(flex-start, center);
				tr {
					width: 100%;
					min-width: 380px;
					@include center-box(space-between, center);
					&:nth-child(odd) {
						background-color: #fff;
					}
					td {
						text-align: left;
					}
				}
			}
		}
		.price-total-container {
			.price-total {
				max-width: 650px;
				margin: 0 auto;
				@include center-box(flex-end, center);
				.price-total-title {
					font-size: 16px;
					font-weight: 700;
					margin: 0 20px 0 0;
					padding: 2px;
					@apply text-secondColor-700;
				}
				.price-total-value {
					font-size: 16px;
					font-weight: 700;
					@apply text-secondColor-700;
					margin: 0 20px 0 0;
					min-width: 90px;
					text-align: right;
				}
			}
		}

		.btns-footer {
			@include center-box();
			.saveBtn {
				@apply bg-redColor-500;
				color: #fff;
				border-radius: 5px;
				padding: 10px 20px;
				font-size: 14px;
				font-weight: 600;
				border: none;
				cursor: pointer;
				margin: 20px auto;
				width: 100%;
				max-width: 150px;
				&:hover {
					@apply bg-redColor-700;
					color: #fff;
				}
			}
		}
	}
}
