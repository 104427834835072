.details-details-panel {
	// Example styles for a slide-out panel
	position: fixed;
	overflow-y: scroll;
	right: 0;
	top: 80px;
	padding-bottom: 80px;
	width: 100%; // Adjust as necessary
	height: 100%;
	background: rgba(#fff, 0.7);

	// transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	@include center-box(flex-end, flex-start);

	// This assumes you toggle a class to show/hide the panel
	&.show {
		transform: translateX(0);
	}

	.details-panel {
		-webkit-box-shadow: -10px 0 10px 0 #dedede;
		box-shadow: -10px 0 10px 0 #dedede;
		width: 70%;
		background: #fff;
		padding: 26px;
		@media (max-width: 900px) {
			width: 100%;
		}
		.details-number {
			margin-bottom: 50px;
			@include center-box(space-between, flex-start);
			.block-left {
				@include center-box(flex-start, center);
				flex-direction: column;
				.details-number-title {
					font-size: 24px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.details-number-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
			.block-right {
				@include center-box(flex-end, center);
				.close-btn {
					@include center-box();
					@apply text-gray-900;
					width: 40px;
					height: 40px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply text-secondColor-500;
					}
				}
				.btn-delete-item {
					@extend %red-btn;
					@apply text-redColor-400;
					@include center-box();
					margin-right: 10px;
					svg {
						margin-left: 10px;
					}
				}
			}
		}
		.progress-guide {
			margin-bottom: 50px;
			@include center-box(space-between, center);
			.progress-guide-item {
				@include center-box(flex-start, center);
				flex-direction: column;
				.progress-guide-item-title {
					font-size: 14px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.progress-guide-item-value {
					width: 100%;
					font-size: 10px;
					font-weight: 400;
					@apply text-secondColor-500;
				}
			}
		}
		.tab-information {
			@extend %detail-blocks;
			max-width: 1000px;
			margin: 0 auto 20px;
			p {
				margin-bottom: 20px;
				border-bottom-style: solid;
				border-bottom-width: 1px;
				@apply border-secondColor-300;
				strong {
					@apply text-secondColor-700;
					margin-right: 20px;
				}
			}
			.field-section-full {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 10px;
				label {
					width: 25%;
				}
				input {
					width: 75%;
					padding: 3px;
					margin-bottom: 10px;
				}
				textarea {
					width: 75%;
					padding: 3px;
				}

				.btn-download-file {
					@extend %red-btn;
					@include center-box();
					margin-top: 20px;
					color: red;
					svg {
						margin-left: 10px;
					}
				}
			}
			.field-section-one-half {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 10px;
				label {
					width: 25%;
					max-width: 90px;
				}
				input,
				textarea {
					width: 75%;
					margin-bottom: 10px;
				}
				.p-tab-info {
					border: none;
				}
			}

			.field-section-half {
				@extend %general-fields;
				@include center-box(space-between, center);
				.inp-col {
					@include center-box(flex-start, center);
					width: 48%;
					.p-tab-info {
						border: none;
					}

					label {
						width: 25%;
						padding: 3px 3px 3px 0;
					}
					input,
					textarea,
					select {
						width: 75%;
						margin-bottom: 10px;
					}
					select {
						padding: 10px;
					}
					.p-tab-active {
						@include center-box(flex-start, center);
						border: none;
						strong {
							margin-right: 30px;
						}
					}
				}
			}
			.btn-save {
				@extend %red-btn;
				@include center-box();
				margin-top: 20px;
				color: red;
				svg {
					margin-left: 10px;
				}
			}
		}
		.user-information {
			@extend %detail-blocks;
			.profilePict {
				@include center-box();
				margin-bottom: 20px;
				width: 150px;
				height: 150px;
				border-radius: 50%;
				overflow: hidden;
				border: 1px solid #ccc;
				img {
					width: 100%;
					height: auto;
				}
			}
			p {
				margin-bottom: 20px;
				border-bottom-style: solid;
				border-bottom-width: 1px;
				@apply border-secondColor-300;
				strong {
					@apply text-secondColor-700;
					margin-right: 20px;
					min-width: 150px;
					display: inline-block;
					padding-right: 5px;
				}
			}
			.progress-bar {
				margin-bottom: 20px;
				@include center-box(flex-start, center);
				label {
					width: 25%;
				}
				input {
					width: 75%;
					padding: 3px;
					margin-bottom: 10px;
				}
				textarea {
					width: 75%;
					padding: 3px;
				}

				.progress-bar-item {
					@include center-box(flex-start, center);
					flex-direction: column;
					.progress-bar-item-title {
						font-size: 14px;
						font-weight: 600;
						width: 100%;
						@apply text-secondColor-700;
					}
					.progress-bar-item-value {
						width: 100%;
						font-size: 16px;
						font-weight: 400;
						@apply text-secondColor-700;
					}
				}
			}
			.progress-bar-fill {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 10px;
				label {
					width: 25%;
				}
				input {
					width: 75%;
					padding: 3px;
					margin-bottom: 10px;
				}
				textarea {
					width: 75%;
					padding: 3px;
				}
			}
			.formSubmitFile {
				@extend %general-fields;
				@include center-box(flex-start, center);
				margin-bottom: 30px;
				label {
					width: 25%;
				}
				input {
					width: 75%;
					padding: 3px;
					margin-bottom: 10px;
				}
				textarea {
					width: 75%;
					padding: 3px;
				}
				// progress bar to upload the file
				.progress-bar {
					margin-bottom: 20px;
					padding: 3px;
					border: 1px solid #ccc;
					.progress-bar-fill {
						background-color: #f00;
						height: 20px;
						width: 0;
					}
				}
				.btn-save {
					@extend %red-btn;
					@include center-box();
					margin-top: 20px;
					color: red;
					svg {
						margin-left: 10px;
					}
				}
			}

			.notesList {
				@include center-box(flex-start, center);
				background-color: white;
				margin: 10px auto;
				padding: 10px 20px;
				width: 100%;
				border-radius: 8px;
				.notes-item-title {
					font-size: 14px;
					font-weight: 600;
					width: 100%;
					@apply text-secondColor-700;
				}
				.notes-item-value {
					width: 100%;
					font-size: 16px;
					font-weight: 400;
					@apply text-secondColor-700;
				}
				.download-array {
					width: 100%;
					@include center-box(flex-start, center);
					border-top: 1px solid #ccc;
					margin-top: 10px;
					padding-top: 10px;
					.notes-item-attachment {
						.btn-download-file {
							position: relative;
							display: inline-block;
							width: 60px;
							@include center-box();
							margin-top: 20px;
							margin: 3px;
							padding: 3px;
							border: 1px solid red;
							border-radius: 25px;
							svg {
								color: red;
							}
							&:hover {
								background-color: red;
								svg {
									color: white;
								}
							}
						}
					}
				}
			}
			.content-block-documents {
				@include center-box(flex-start, flex-start);

				.btn-download-file {
					@extend %red-btn;
					padding: 5px 10px;
					@include center-box();
					margin: 10px;
					color: red;
					min-width: 150px;
					svg {
						margin-left: 10px;
					}
					&:hover {
						@apply bg-redColor-400;
					}
				}
				.btn-download-file-list {
					border: 1px solid #ccc;
					@include center-box(space-between, center);
					margin: 10px;
					padding: 3px 10px;
					border-radius: 25px;
					svg {
						margin-left: 10px;
						padding: 2px;
						&:hover {
							border-radius: 50%;
							@apply bg-redColor-400;
							@apply text-white;
						}
					}
				}
			}
		}
		.sale-information {
			@extend %detail-blocks;
		}
		.client-information {
			@extend %detail-blocks;
		}
		.structure-details {
			@extend %detail-blocks;
		}
		.notes-block {
			@extend %detail-blocks;
		}
		.installation-permits {
			@extend %detail-blocks;
		}
		.notes-class {
			background-color: #fff;
			padding: 20px;
			margin-bottom: 10px;
			@extend %detail-blocks;
			.data-content {
				&:first-child {
					border-bottom: 1px solid #ccc;
				}
			}
			p {
				border: none;
				padding-bottom: 5px;
				margin-bottom: 5px;
				strong {
					@apply text-secondColor-700;
				}
			}
		}
	}
}
